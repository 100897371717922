// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import "~noty/src/noty.scss";
@import "~noty/src/themes/mint.scss";

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';
@import "~tom-select/src/scss/tom-select.bootstrap5";
